<template>
  <div class="task" id="task">
      <van-sticky>
        <ListSearch :listConfig="listConfig" @search="search" style="position: fixed; z-index: 1000; width: 100%;" />
    </van-sticky>
	<HYList ref="HYList" v-bind="listConfig" style="position: relative; top: 113px;" >
		<template #columnHead="{ row }">
			<div style="height: 2.46rem;">
                <div class="carInfo">
                    <p class="plateNo">
                        {{ row.plateNo }} &nbsp; 
                        <TagTip>
                            <template #tipval>
                                <span>{{ row.policyMaturityDay }}天后到期</span>
                            </template>
                        </TagTip>
                        <a :href="`tel:${row.ownerPhoneNo}`" class="phone-img">
                            <van-image
                                style="height: 0.5rem; width: 0.5rem;"
                                :src="require('@/assets/images/phone.png')"
                            />
                        </a>
                    </p>

                </div>
                <div class="line"></div>
                <div class="userInfo">
                    <div class="userInfoCon" @click="toDetail(row)">
                        <p>
                            <span class="title">车主姓名：</span>
                            <span class="titledes">{{ row.ownerName }}</span>
                        </p>
                        <p style="margin-top: 0.05rem;">
                            <span class="title">车架号：</span>
                            <span class="titledes">{{ row.frameNo }}</span>
                        </p>
                        <p style="margin-top: 0.05rem;">
                            <span class="title">到期时间：</span>
                            <span class="titledes">{{ dayjs(row.policyEndTime).format('YYYY-MM-DD') }}</span>
                        </p>
                    </div>
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '0'"
                        :src="require('@/assets/images/taskStatus0.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '1'"
                        :src="require('@/assets/images/taskStatus1.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '2'"
                        :src="require('@/assets/images/taskStatus2.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '3'"
                        :src="require('@/assets/images/taskStatus3.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '4'"
                        :src="require('@/assets/images/taskStatus4.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '5'"
                        :src="require('@/assets/images/taskStatus5.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.trackStatus === '6'"
                        :src="require('@/assets/images/taskStatus6.png')"
                        @click.stop="follow(row)"
                    />
                </div>
			</div>
		</template>
		<template #columnFoot="{}">
		</template>
		<template #empty>
			<div class="none-tip">
				<van-image
				:src="require('@/assets/images/none_data.jpg')"
				/>
				<p>尊敬的用户，您当前暂无任务信息</p>
			</div>
		</template>
	</HYList>
    <FollowForm ref="FollowForm" :id="taskId" @updateList="search" />
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import dayjs from 'dayjs'
import { mapState } from "vuex";
import ListSearch from './components/listSearch'
import FollowForm from './module/followForm.vue'
import TagTip from '@/components/TagTip.vue'
import { isWxAuthorize } from '@/utils/weixin'
import { Dialog } from "vant";
export default {
	name:'OrderList',
	components: { HYList, ListSearch, FollowForm, TagTip },
	provide () {
		return {
			pageContext: this.pageContext
		}
	},
	data() {
		return {
            taskId: '',
			listConfig:{
				api:{
					url:'/afis-renewal/renewal/renewalTask/wxList'
				},
				form:{
					todayTaskQueryCondition:'',
                    trackStatus: null,
                    pageQueryFlag: '2'
				},
				column:[],
                styleObj: {}
			}
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"]),
        ...mapState("user", ["currentMenus"])
    },
	async created(){
        this.$nextTick(() => {
            if (this.currentMenus.length > 0 && !this.currentMenus.includes('/mpRenewalTask')) {
                // authorize("#"+ to.fullPath)
                Dialog({ message: '当前用户暂时没有该页面权限' });
                this.$router.push('/mpArriveVehicleRecord')
                return false
            }
        })
        await isWxAuthorize()
	},
	mounted () {
        task.style.height = (document.documentElement.clientHeight - 60) + 'px'
    },
	methods:{
		dayjs,
        search () {
            this.$refs.HYList.onRefresh()
        },
        toDetail (row) {
            this.$router.push({
                path: '/follow',
                query: {
                   id: row.taskId 
                }
            })
        },
        follow (obj) {
            this.taskId = obj.taskId
            this.$refs.FollowForm.show = true
            this.$refs.FollowForm.init()
        }
	}
}
</script>
<style lang="scss" scoped>
.task {
    overflow: auto;
    p {
        margin: 0;
        padding: 0;
    }
    .none-tip {
        position: relative;
        top: 20%;
        text-align: center;
        p {
            margin-top: 10px;
            text-align: center;
        }
    }
    .carInfo {
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.245rem;
        p {
            margin: 0;
            padding: 0;
        }
        .plateNo {
            font-size: 0.3rem;
            position: inherit;
            .surplus {
                color: #37B8AD;
                font-size: 0.24rem;
                display: inline-block;
                width: 90px;
                border: 1px solid #37B8AD;
                height: 0.4rem;
                line-height: 0.4rem;
                text-align: center;
                border-radius: 0.04rem;
            }
            .phone-img {
                height: 0.5rem; width: 0.5rem; position: absolute; right: 0.31rem; top: 0.15rem;
            }
        }
    }
    .line {
        border-bottom: 1px solid rgba(151,151,151, .3);
    }
    .userInfo {
        padding-left: 0.245rem;
        margin-top: 0.175rem;
        .userInfoCon {
            height: 0.37rem;
            line-height: 0.37rem;
            font-size: 0.26rem;
        }
        .title {
            color: #B6B9BF;
            font-size: 0.26rem;
            display: inline-block;
            width: 1.3rem;
            text-align: left;
        }
        .titledes {
            font-size: 0.26rem;
            color: #1B1B1B;
        }
        .status-img {
            width: 1.2rem;
            height: 1.2rem;
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            right: 0.84rem;
            bottom: 0.27rem;
        }
    }
}
</style>