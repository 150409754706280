<template>
    <div class="listSearch">
        <div class="searchInput">
            <van-search
                v-model="listConfig.form.todayTaskQueryCondition"
                show-action
                label=""
                placeholder="请输入姓名/车牌号/车架号/电话"
                @search="search"
                >
                <template #action>
                    <div @click="search" style="color: #35B8AD; font-size: 0.32rem;">搜索</div>
                </template>
                </van-search>
        </div>
        <div class="searchSelect">
            <div class="select-border">
                <van-dropdown-menu class="select" active-color="#35B8AD" z-index="1000">
                    <van-dropdown-item v-model="listConfig.form.pageQueryFlag" @change="search" :options="dictMap['RENEWAL_TASK_QUERY_SCOPE']" />
                </van-dropdown-menu>
                <span class="center-line"></span>
                <van-dropdown-menu class="select" active-color="#35B8AD" z-index="1000">
                    <van-dropdown-item v-model="listConfig.form.trackStatus" @change="search" :options="trackStatusDict" />
                </van-dropdown-menu>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import store from '@/store'
export default {
    props: {
        listConfig: {
            type: Object,
            required: true
        }  
    },
    data () {
        return {
            trackStatusDict: []
        }
    },
    computed: {
        ...mapState("dict", ["dictMap"])
    },
    created () {
        store.dispatch('dict/loadDict')
        this.trackStatusDict = [
            {
                id: 'All',
                text: '全部',
                simpleLabel: '全部',
                status: 'All',
                value: null
            },
            ...this.dictMap['TRACK_STATUS']
        ]
    },
    methods: {
        search () {
            this.$emit('search')
        }
    }
}
</script>
<style lang="scss" scoped>
.listSearch {
    background: #fff;
    .searchInput {
        border-bottom: 1px solid #E8E8E8;
        height: 58px;
    }
    .searchSelect ::v-deep{
        height: 54px;
        line-height: 54px;
        padding-left: 10px;
        font-size: 13px;
        .select-border {
            display: inline-block;
            font-size: 13px;
            height: 32px;
            line-height: 32px;
            background: #FFFFFF;
            box-shadow: 0 0 6px 0 rgba(199,199,199,0.60);
            border-radius: 6px;
            border-radius: 6px;
        }
        .select {
            width: 2.5rem;
            font-size: 13px;
            display: inline-block;
        }
        .van-dropdown-menu__bar{
            height: 32px;
            box-shadow: unset;
        }
        .van-dropdown-menu__title {
            color: #8C899A;
            font-size: 13px;
        }
        .van-dropdown-item--down {
            top: 113px !important;
            border-top: 1px solid #E8E8E8;
        }
        .van-dropdown-item__option {
            color: #252525;
            font-size: 13px;
        }
        .van-dropdown-item__option--active {
            color: rgb(53, 184, 173);
        }
        .center-line {
            display: inline-block;
            height: 14px;
            border-right: 1px solid #E9E9E9;
            position: relative;
            top: 3px;
        }
    }
}
</style>
